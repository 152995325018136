"use-strict";

import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";

import theme from "./theme";

import "./theme/styles/fonts.css";

export const wrapRootElement = ({ element }) => (
  <HelmetProvider>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {element}
    </ThemeProvider>
  </HelmetProvider>
);
